<app-header></app-header>

<mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item>
        <!--<video class="video-fluid" autoplay loop muted>
            <source src="./assets/video/BannerTS_v2.mp4" type="video/mp4" />
        </video>-->
        <img src="./assets/video/back.png" alt="" class="img-fluid" width="100%">
    </mdb-carousel-item>

</mdb-carousel>

<div class="container mt-5 text-center mb-5">

    <div class="row">

        <div class="col-md-4"><img src="./assets/img/home-nem_v2.png" class="mt-3 mb-3 img-fluid"></div>
        
        <div class="col-md-8">
            <h2 class="font-weight-bold text-left mb-5">Nueva Escuela Mexicana</h2>
            <hr class="hrcolor">
            <p class="text-left">La Nueva Escuela Mexicana, buscará la equidad, la excelencia y la mejora continua en la educación, para lo cual colocará al centro de la acción pública el máximo logro de aprendizaje de las niñas, niños, adolescentes y jóvenes. Tendrá como objetivos el desarrollo humano integral del educando, reorientar el Sistema Educativo Nacional, incidir en la cultura educativa mediante la corresponsabilidad e impulsar transformaciones sociales dentro de la escuela y en la comunidad.</p>
        </div>
    </div>

<br>

    <div class="row">

      <div class="col-md-8">
            <h2 class="font-weight-bold text-left mb-3">Telesecundaria</h2>
            <hr class="hrcolor">
            <p class="text-left">La Telesecundaria es un servicio de Educación Básica que, aliado a otros servicios del nivel, atiende a la comunidad estudiantil de Educación Secundaria. Su misión se logra mediante un modelo pedagógico caracterizado por el uso de Libros de Texto Gratuitos y materiales complementarios impresos, audiovisuales e informáticos. Una maestra o maestro organiza y construye el proceso de enseñanza en el aula para promover acuerdos y nuevos aprendizajes. Estos aprendizajes permitirán a las y los alumnos continuar estudiando y ser promotores de una mejor forma de vida de las personas que los rodean.</p>
        </div>
        
        <div class="col-md-4"><img src="./assets/img/home-tel_v2.png" class="mt-3 img-fluid"></div>

    </div>
    <br><br>
    
    <!--Materiales por Grados -->
    <h2 class="font-weight-bold">Descubre los   Materiales Educativos</h2>
    <hr>
    <div class="row mt-5">
        <div class="col-md-4">
            <!--Card-->
            <mdb-card cascade="true" narrower="true" [routerLink]="['/MaterialesE/Inicio', { outlets: { Secc: 'Libros' }}]" style="box-shadow: none!important;     background-color: transparent !important;">
            <!-- <mdb-card cascade="true" narrower="true"  style="box-shadow: none!important;     background-color: transparent !important;"> -->

                <!--Card image-->
                <div class="view view-cascade overlay waves-light" mdbWavesEffect style="box-shadow: none!important;" >
                    <mdb-card-img src="./assets/img/primer_grado.png"></mdb-card-img>
                    <a>
                        <div class="mask" style="background-color: rgb(221 95 137 / 25%)"></div>
                    </a>
                </div>
                <!--/Card image-->

                <mdb-card-body cascade="true" class="text-center">

                    <!--Title-->
                    <h4 class="card-title">Primer Grado</h4>
                    <!--Text-->

                </mdb-card-body>
                <!--/.Card content-->

            </mdb-card>
            <!--/.Card-->
        </div>
        <div class="col-md-4">
            <!--Card-->
            <mdb-card cascade="true" narrower="true" [routerLink]="['/MaterialesE2/Inicio', { outlets: { Secc: 'Libros' }}]" style="box-shadow: none!important;     background-color: transparent !important;">

                <!--Card image-->
                <div class="view view-cascade overlay waves-light" mdbWavesEffect style="box-shadow: none!important;">
                    <mdb-card-img src="./assets/img/segundo_grado.png"></mdb-card-img>
                    <a>
                        <div class="mask" style="background-color: rgb(107 64 146 / 25%)"></div>
                    </a>
                </div>
                <!--/Card image-->

                <mdb-card-body cascade="true" class="text-center">

                    <!--Title-->
                    <h4 class="card-title">Segundo Grado</h4>
                    <!--Text-->

                </mdb-card-body>
                <!--/.Card content-->

            </mdb-card>
            <!--/.Card-->
        </div>



        <!-- NUEVO TERCERO -->

        <div class="col-md-4">
          <!--Card-->
          <mdb-card cascade="true" narrower="true" [routerLink]="['/MaterialesE3/Inicio', { outlets: { Secc: 'Libros' }}]" style="box-shadow: none!important;     background-color: transparent !important;">

              <!--Card image-->
              <div class="view view-cascade overlay waves-light" mdbWavexsEffect style="box-shadow: none!important;">
                  <mdb-card-img src="./assets/img/tercer_grado.png"></mdb-card-img>
                  <a>
                      <div class="mask" style="background-color: rgb(101 159 171 / 25%)"></div>
                  </a>
              </div>
              <!--/Card image-->

              <mdb-card-body cascade="true" class="text-center">

                  <!--Title-->
                  <h4 class="card-title">Tercer Grado</h4>
                  <!--Text-->

              </mdb-card-body>
              <!--/.Card content-->

          </mdb-card>
          <!--/.Card-->
      </div>
      <!-- FIN NUEVO TERCERO -->


    </div>

<hr class="mb-5 mt-5">
<!--CARRRUSEL DE NOTICIAS-->
    <mdb-carousel class="carousel slide mt-5 carousel-fade" [type]="'carousel-thumbnails'" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let item of Carousel">
            <a *ngIf="item.url_carrusel" href="{{item.url_carrusel}}" target="{{item.pestana_nueva == 1 ? '_blank' : '_self'}}">
                <img class="d-block w-100" src="{{item.img_carrusel}}" alt="First slide">
            </a>
            <img *ngIf="!item.url_carrusel" class="d-block w-100" src="{{item.img_carrusel}}" alt="{{item.img_carrusel}}">
        </mdb-carousel-item>

    </mdb-carousel>


<hr class="mb-5 mt-5">


<!-- Apliacion movil-->
<section class="my-5 mb-5">
  <swiper [config]="config">
    <div class="swiper-wrapper">
      
        <div class="swiper-slide">
        <!-- Grid row -->
        <div class="row">
          <!-- Grid column -->
          <div class="col-lg-5">
            <!-- Featured image -->
            <div class="view overlay rounded  mb-lg-0 mb-4 waves-light" mdbWavesEffect data-swiper-parallax="-100" data-swiper-parallax-duration="500">
              <img class="img-fluid" src="./assets/img/tsapp_TARG.png" alt="Grupo de Facebook de Telesecundaria">
              <a>
                <div class="mask rgba-white-slight"></div>
              </a>
            </div>
          </div>
          <!-- Grid column -->
          <div class="col-lg-7">
            <!-- Post title -->
            <h3 class="font-weight-bold mb-3" data-swiper-parallax="-200" data-swiper-parallax-duration="600"><strong>Somos Telesecundaria</strong></h3>
            <!-- Excerpt -->
            <p data-swiper-parallax="-300" data-swiper-parallax-duration="700">La comunidad “Somos Telesecundaria” tiene como objetivo fortalecer la implementación del modelo pedagógico de Telesecundaria, promoviendo el uso didáctico de los materiales educativos elaborados específicamente para esta modalidad.</p>
            <!-- Read more button -->
            <a data-swiper-parallax="-400" data-swiper-parallax-duration="800" mdbBtn gradient="purple" mdbWavesEffect href="https://www.facebook.com/groups/somos.telesecundaria.mx" target="_blank">Únete a la Comunidad</a>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row   -->
      </div>
      
      

    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
    <!-- Add Arrows -->
  </swiper>
</section>

</div> <!--Cierre del comtainer-->

<app-footer></app-footer>