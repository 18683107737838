<div class="modal-header  md_custom">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" style="color: #F3F5F4;">×</span>
    </button>
    <h3 class="modal-title w-100 text-center  mt-4 font-weight-bold" id="myModalLabel">Telesecundaria</h3>
</div>

<!-- Info -->
<div class="container md_con_custom">
    <section class="my-2">
        <iframe width="100%" height="415" [src]="url | domseguro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" muted style="border-radius: 5px;">
        </iframe>
    </section>
</div>
<!-- Info -->
