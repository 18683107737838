import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModaldiComponent } from '../modaldi/modaldi.component';
import { CarouselService } from '../services/carousel.service';
import { SwiperOptions } from 'swiper';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  modalRef: MDBModalRef;
  Carousel: any = [];
  cookieValue: string;


  config: SwiperOptions = {
    slidesPerView: 1,
    parallax:true,    
    autoplay:true,
    speed:1000,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
     navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    
  }; 



  constructor(private modalService: MDBModalService, private _carousel:CarouselService, private cookieService: CookieService) { }

  openModal(id_grado, id_perfil) {
    this.modalRef = this.modalService.show(ModaldiComponent, {
        data:{grado:id_grado, perfil:id_perfil},
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        class: 'modal-dialog modal-lg modal-top modal-notify  modal-dialog-scrollable ldim',
        containerClass: '',
        animated: true
    });
  }
  ngOnInit(): void {
    this.cookieValue = this.cookieService.get('modalinfo');
    if(!Boolean(this.cookieValue)){
      this.cookieService.set('modalinfo', 'true' ,1);
      this.openModalinformacion();
    }

    this._carousel.getCarousel().subscribe(res =>{
      this.Carousel = res;
      //console.log(this.Carousel);
    },err =>{
      console.log(err);
    });

    
  }

  openModalinformacion() {
    this.modalRef = this.modalService.show(ModalInfoComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-lg modal-top',
      containerClass: 'top',
      animated: true,
    });
  }
}
