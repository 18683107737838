import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

@Pipe({
  name: 'domseguro'
})
export class DomseguroPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    if (!url) {
      throw new Error('La URL no puede estar vacía.');
    }

    try {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } catch (error) {
      console.error('Error al sanitizar la URL:', error);
      throw new Error('La URL proporcionada no es válida.');
    }
  }

}
